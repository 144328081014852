import Cookies from 'js-cookie'
import Tablesort from 'tablesort'

$(document).ready ->
  $('.btn-file :file').on 'fileselect', (event, numFiles, label) ->
    input = $(this).parents('.input-group').find(':text')
    if numFiles > 1
      caption = numFiles + ' Dateien ausgewählt'
    else
      caption = label
    input.val(caption) if input.length

  $('select.submit-on-change').on 'change', ->
    $(this).closest('form').submit()

  $('select.addressable-country-select').on 'change', ->
    $.ajax('/aliases/regions_for_country', {data: {country: $(this).find('option:selected').text()}})

  # PDF support detection
  if Cookies != undefined && Cookies.get('pdf_support') == undefined
    # Details about PDF support detection:  http://www.pinlady.net/PluginDetect/PDFReader/
    pdf_detection_done = (pdf_reader) ->
      # PluginDetect does not actually detect a version (different vendors using different version numbers etc.).
      # Asking for minVersion >= 0 means asking 'Is there PDF support?'. Possible return values:
      # 0    - plugin installed and enabled
      # 0.15 - IE only; plugin installed but not enabled for <object>/<embed> tags
      # 0.5  - plugin detection is not completed yet, not relevant as onDetectionDone is used
      # -1   - plugin not installed or disabled
      # -1.5 - IE only; plugin status unknown
      # -3   - bad argument(s) to isMinVersion call
      pdf_status = pdf_reader.isMinVersion('PDFReader', 0)
      Cookies.set 'pdf_support', pdf_status.toString(), {path: '/'}
    dummy_pdf = '/pdfs/pdf_support_detection.pdf'
    detect_non_adobe_ie = 1
    PluginDetect.onDetectionDone 'PDFReader', pdf_detection_done, dummy_pdf, detect_non_adobe_ie

  document.querySelectorAll('table.sortable').forEach (table) ->
    new Tablesort(table)

$(document).on 'ready page:load', ->
  $('.datepicker').datepicker {
    format: 'dd.mm.yyyy',
    language: 'de'
  }

  $('.datetimepicker').datetimepicker {
    locale: 'de'
  }

  $('a[href="#send_document_kind_parsing_failed_message"]').click (event) ->
    $('form#document_kind_parsing_failed_form').attr('action', this.dataset.targetUrl)

  $('input.token4').bind 'keyup', (e) ->
    elem = $(this)
    setTimeout ->
      if elem.val().length == parseInt(elem.attr('maxlength'), 10)
        elem.next('input').focus()
      else if elem.val().length == 0 && e.keyCode == 8
        # keyCode 8 = Backspace
        elem.prev('input').focus()


$(document).on 'change', '.btn-file :file', ->
  input = $(this)
  if input.get(0).files
    numFiles = input.get(0).files.length
  else
    numFiles = 1
  label = input.val().replace(/\\/g, '/').replace(/.*\//, '')
  input.trigger('fileselect', [numFiles, label])
  if input.hasClass('multiple-files')
    add_file_input(input)

add_file_input = (input) ->
  form_group = $(input.closest("div[class='form-group']"))
  clone = form_group.clone(true)
  clone.find('input').val('')
  clone.insertAfter(form_group)
